"use client";

import { handleRequest } from "./common";

export async function getShipmentOrders(options?: { status?: string } ) {
  let url = '/shipment_orders';
  if (options) {
    const {
      status
    } = options;
    if (status) {
      url += `?status=${status}`;
    }
  }
  const data = await handleRequest(url, 'GET');
  return data || [];
}

export async function getShipmentOrder(id: string) {
  const data = await handleRequest(`/shipment_orders/${id}`, 'GET');
  console.log(data)
  return data;
}

export async function payForShipmentOrder(shipmentOrderId: string, amount: number, token: string) {
  const data = await handleRequest(`/shipment_orders/${shipmentOrderId}/payment`, 'POST', { 
    amount_cents: amount,
    currency: "thb",
    token: token,
    return_uri: `${process.env.NEXT_PUBLIC_URL}/shipment_orders/${shipmentOrderId}/complete`
  });

  return data;
}

export async function getShipmentOrderPayment(shipmentOrderId: string) {
  const data = await handleRequest(`/shipment_orders/${shipmentOrderId}/payment`, 'GET');
  return data;
}

export async function startShipment(shipmentOrderId: string) {
  const data = await handleRequest(`/shipment_orders/${shipmentOrderId}/start`, 'GET');
  return data;
}

export async function createShipmentOrder(shipmentOrder: any) {
  const data = await handleRequest('/shipment_orders', 'POST', shipmentOrder)
  return data || {};
}

export async function updateShipmentOrder(shipmentOrderId: string, shipmentOrder: any) {
  const data = await handleRequest(`/shipment_orders/${shipmentOrderId}`, 'PUT', shipmentOrder)
  return data || {};
}