'use client';

import { IShipmentContextProps } from './shipment';
import { TCountry } from '@/constants/countries.constant';
import { TAddress } from '@/types/address.type';
import { TParcelOption } from '@/types/parcel.type';
import { TShippingOption } from '@/types/shippingOptions.type';
import { TPaymentMethod } from '@/types/paymentMethods.type';

import React, {
  createContext,
  FC,
  ReactNode,
  useMemo,
  useState,
} from 'react';

export const ShipmentOrderContext = createContext<IShipmentContextProps>({} as IShipmentContextProps);

interface IShipmentOrderContextProviderProps {
	children: ReactNode;
}
export const ShipmentOrderContextProvider: FC<IShipmentOrderContextProviderProps> = ({ children }) => {
  const [destinationCountry, setDestinationCountry] = useState<TCountry>({} as TCountry);
  const [currentAddressSlug, setCurrentAddressSlug] = useState<string>('');
  const [address, setAddress] = useState<TAddress>({} as TAddress);
  const [shipmentAddressId, setShipmentAddressId] = useState<string>('');
  const [parcelOption, setParcelOption] = useState<TParcelOption>({} as TParcelOption);
  const [shippingOption, setShippingOption] = useState<TShippingOption>({} as TShippingOption);
  const [paymentMethod, setPaymentMethod] = useState<TPaymentMethod>('' as TPaymentMethod);
  const [callerPage, setCallerPage] = useState<string>('');
  const [editingAddress, setEditingAddress] = useState<TAddress>({} as TAddress);

  const values: IShipmentContextProps = useMemo(
    () => ({
      destinationCountry,
      setDestinationCountry,
      currentAddressSlug,
      setCurrentAddressSlug,
      address,
      setAddress,
      shipmentAddressId,
      setShipmentAddressId,
      parcelOption,
      setParcelOption,
      shippingOption,
      setShippingOption,
      paymentMethod,
      setPaymentMethod,
      callerPage,
      setCallerPage,
      editingAddress,
      setEditingAddress
    }), [destinationCountry, currentAddressSlug, address, shipmentAddressId, parcelOption, shippingOption, paymentMethod, callerPage, editingAddress]
  );

  return <ShipmentOrderContext.Provider value={values}>{children}</ShipmentOrderContext.Provider>;
}