import React from 'react';
import Image from 'next/image';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';

import { FastbuyLogo } from '@/assets/images';
// import DefaultHeaderRightCommon from './_common/DefaultHeaderRight.common';
// import SearchPartial from './_partial/Search.partial';

const DefaultHeaderTemplate = () => {
	return (
		// <Header>
		// 	<HeaderLeft>
		// 		<SearchPartial />
		// 	</HeaderLeft>
		// 	<HeaderRight>
		// 		<DefaultHeaderRightCommon />
		// 	</HeaderRight>
		// </Header>
		<Header>
			<HeaderLeft><></></HeaderLeft>
			<div className="flex flex-row justify-center items-center">
				<Image src={FastbuyLogo} width={200} height={71} alt="Fastbuy Logo" />
			</div>
			<HeaderRight>
				<></>
			</HeaderRight>
	</Header>
	);
};

export default DefaultHeaderTemplate;
