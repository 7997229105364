'use client';

import { TCountry } from '@/constants/countries.constant';
import { TAddress } from '@/types/address.type';
import { TParcelOption } from '@/types/parcel.type';
import { TShippingOption } from '@/types/shippingOptions.type';
import { TPaymentMethod } from '@/types/paymentMethods.type';

import React, {
  createContext,
  FC,
  ReactNode,
  useMemo,
  useState,
  Dispatch, 
  SetStateAction
} from 'react';

export interface IShipmentContextProps {
  destinationCountry: TCountry;
  setDestinationCountry: Dispatch<SetStateAction<TCountry>>;
  currentAddressSlug: string;
  setCurrentAddressSlug: Dispatch<SetStateAction<string>>;
  address: TAddress;
  setAddress: Dispatch<SetStateAction<TAddress>>;
  shipmentAddressId: string;
  setShipmentAddressId: Dispatch<SetStateAction<string>>;
  parcelOption: TParcelOption;
  setParcelOption: Dispatch<SetStateAction<TParcelOption>>;
  shippingOption: TShippingOption;
  setShippingOption: Dispatch<SetStateAction<TShippingOption>>;
  paymentMethod: TPaymentMethod;
  setPaymentMethod: Dispatch<SetStateAction<TPaymentMethod>>;
  callerPage: string;
  setCallerPage: Dispatch<SetStateAction<string>>;
  editingAddress: TAddress;
  setEditingAddress: Dispatch<SetStateAction<TAddress>>;
}

export const ShipmentContext = createContext<IShipmentContextProps>({} as IShipmentContextProps);

interface IShipmentContextProviderProps {
	children: ReactNode;
}
export const ShipmentContextProvider: FC<IShipmentContextProviderProps> = ({ children }) => {
  const [destinationCountry, setDestinationCountry] = useState<TCountry>({} as TCountry);
  const [currentAddressSlug, setCurrentAddressSlug] = useState<string>('');
  const [address, setAddress] = useState<TAddress>({} as TAddress);
  const [shipmentAddressId, setShipmentAddressId] = useState<string>('');
  const [parcelOption, setParcelOption] = useState<TParcelOption>({} as TParcelOption);
  const [shippingOption, setShippingOption] = useState<TShippingOption>({} as TShippingOption);
  const [paymentMethod, setPaymentMethod] = useState<TPaymentMethod>('credit_card' as TPaymentMethod);
  const [callerPage, setCallerPage] = useState<string>('');
  const [editingAddress, setEditingAddress] = useState<TAddress>({} as TAddress);

  const values: IShipmentContextProps = useMemo(
    () => ({
      destinationCountry,
      setDestinationCountry,
      currentAddressSlug,
      setCurrentAddressSlug,
      address,
      setAddress,
      shipmentAddressId,
      setShipmentAddressId,
      parcelOption,
      setParcelOption,
      shippingOption,
      setShippingOption,
      paymentMethod,
      setPaymentMethod,
      callerPage,
      setCallerPage,
      editingAddress,
      setEditingAddress
    }), [destinationCountry, currentAddressSlug, address, shipmentAddressId, parcelOption, shippingOption, paymentMethod, callerPage, editingAddress]
  );

  return <ShipmentContext.Provider value={values}>{children}</ShipmentContext.Provider>;
}