'use client'

import { apiRequest } from './request';

export async function handleRequest(
  endpoint: string,
  method: string = "GET",
  body: any = null
) {
  const data = await apiRequest(endpoint, method, body);

  if (data.status === 401) {
    window.location.href = '/login';
    return;
  }
  return data;
}