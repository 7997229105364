import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"display\":\"swap\",\"preload\":false,\"style\":[\"normal\",\"italic\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\",\"latin-ext\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/_app.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/_providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/index.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/vendors.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-date-range/dist/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-date-range/dist/theme/default.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/Wrapper/Wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/router/AsideRouter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/router/HeaderRouter.tsx");
