import React, { useEffect, useState } from "react";
import { appPages } from "@/config/pages.config";
import { getAccountStats } from "@/actions";
import Aside, {
  AsideBody,
  AsideHead,
} from "../../../components/layouts/Aside/Aside";
import LogoAndAsideTogglePart from "./_parts/LogoAndAsideToggle.part";
import Nav, { NavItem } from "../../../components/layouts/Navigation/Nav";

type TAccountStats = {
  total_parcels?: number;
};

const DefaultAsideTemplate = () => {
  const [stats, setStats] = useState<TAccountStats>({ total_parcels: 0 });
  useEffect(() => {
    const fetchAccountStats = async () => {
      const data = await getAccountStats();
      setStats(data);
    };

    fetchAccountStats();
  }, []);
  const isDisabled = stats.total_parcels === 0;
  return (
    <Aside>
      <AsideHead>
        <LogoAndAsideTogglePart />
      </AsideHead>
      <AsideBody>
        <Nav>
          <NavItem {...appPages.homePage} />
          {!isDisabled && (
            <>
              <NavItem {...appPages.parcelsPage} />
              <NavItem {...appPages.shipmentPage.subPages.createShipmentPage} />
              <NavItem
                {...appPages.shipmentOrderPage.subPages.shipmentOrdersPage}
              />
            </>
          )}
        </Nav>
      </AsideBody>
    </Aside>
  );
};

export default DefaultAsideTemplate;
