import React, { FC, HTMLAttributes } from 'react';
import Image from 'next/image';
import { FastbuyLogo } from "@/assets/images";

type ILogoTemplateProps = HTMLAttributes<HTMLOrSVGElement>;
const LogoTemplate: FC<ILogoTemplateProps> = (props) => {
	const { ...rest } = props;
	return (
		<Image src={FastbuyLogo} width={200} height={71} alt="Fastbuy Logo" {...rest}/>
	);
};

export default LogoTemplate;
